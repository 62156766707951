<nz-select
  class="by-w-100"
  [formControl]="control"
  [nzCustomTemplate]="selectTemplate"
  [byChildrenStyles]="departmentSelectChildrenStyles"
  [nzDisabled]="isDisabled"
  [nzAllowClear]="allowClear"
  nzPlaceHolder="{{
    'placeholder.select'
      | translate: { param: 'department' | translate }
      | capitalize
  }}"
  (nzBlur)="onTouched()"
>
  <nz-option
    *ngFor="let billDepartment of billsDepartments"
    [nzDisabled]="disabledBillsDepartments[billDepartment.id]"
    [nzValue]="billDepartment.id"
    [nzLabel]="billDepartment.label"
    nzCustomContent
  >
    <ng-container
      [ngTemplateOutlet]="optionTemplate"
      [ngTemplateOutletContext]="{ $implicit: billDepartment }"
    ></ng-container>
  </nz-option>
</nz-select>

<ng-template #selectTemplate let-selected>
  <ng-container
    [ngTemplateOutlet]="optionTemplate"
    [ngTemplateOutletContext]="{
      $implicit: billDepartmentsRecords | get: selected?.nzValue
    }"
  ></ng-container>
</ng-template>

<ng-template #optionTemplate let-department let>
  <div nz-row nzJustify="space-between" nzAlign="middle">
    <div nz-col>
      {{ department.label }}
      <ng-container
        *ngIf="vatQuotesGroupedById | get: department?.vat_quote_id as vatQuote"
        >-
        <strong class="color--azure"> {{ +vatQuote.value }}%</strong>
        <span nz-tooltip [nzTooltipTitle]="vatQuote.description"
          >({{ vatQuote.description | truncate: 30:'...' }})</span
        >
      </ng-container>
    </div>
    <div nz-col>
      <div class="by-badge by-badge--type by-mr-5">
        {{ department.type | translate | upperfirst }}
      </div>
      <div class="by-badge by-badge--production-type">
        {{ department.production_type | translate | upperfirst }}
      </div>
    </div>
  </div>
</ng-template>
