import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { SubSink } from 'subsink';

import { ChildStyle } from '../../directives/children-styles/children-styles.directive';
import { BillDepartment, PlaceVatQuote } from '../../models';

@Component({
  selector: 'by-bills-departments-select',
  templateUrl: './bills-departments-select.component.html',
  styleUrls: ['./bills-departments-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BillsDepartmentsSelectComponent),
      multi: true,
    },
  ],
})
export class BillsDepartmentsSelectComponent
  implements OnChanges, OnDestroy, ControlValueAccessor {
  @Input()
  billsDepartments: BillDepartment[];

  @Input() vatQuotesGroupedById: Record<number, PlaceVatQuote>;

  @Input()
  disabledBillsDepartments: { [billDepartmentId: number]: boolean } = {};

  @Input()
  isDisabled = false;

  @Input()
  allowClear = false;

  departmentSelectChildrenStyles: ChildStyle[] = [
    { selector: 'nz-select-item', classes: ['by-w-100'] },
  ];

  billDepartmentsRecords: Record<number, BillDepartment>;

  control = this.formBuilder.control(null);

  onTouched: () => void;

  private subs = new SubSink();

  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const { billsDepartments } = changes;

    if (billsDepartments && this.billsDepartments) {
      this.setBillDepartmentsRecords();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  writeValue(billDepartmentId: number) {
    this.control.patchValue(billDepartmentId || null);
    this.cdr.markForCheck();
  }

  registerOnChange(fn: (billDepartmentId: number) => void) {
    this.subs.add(this.control.valueChanges.subscribe(fn));
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  private setBillDepartmentsRecords() {
    this.billDepartmentsRecords = this.billsDepartments.reduce(
      (records, { id, ...department }) => {
        records = { ...records, [id]: department };
        return records;
      },
      {},
    );
  }
}
