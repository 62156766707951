import { AfterViewChecked, Directive, ElementRef, Input } from '@angular/core';

export interface ChildStyle {
  selector: string;
  style?: string;
  classes?: string[];
}

@Directive({
  selector: '[byChildrenStyles]',
})
export class ChildrenStylesDirective implements AfterViewChecked {
  @Input()
  byChildrenStyles: ChildStyle[];

  private nativeElement: Element;

  constructor(hostElement: ElementRef) {
    this.nativeElement = hostElement.nativeElement;
  }

  ngAfterViewChecked() {
    if (!this.byChildrenStyles) {
      return;
    }

    this.byChildrenStyles.forEach((childStyle) =>
      this.attachStyleToElement(childStyle),
    );
  }

  private attachStyleToElement({ selector, style, classes }: ChildStyle): void {
    const element = this.nativeElement.querySelector<HTMLElement>(selector);

    if (!element) {
      return;
    }

    if (style) {
      element.setAttribute('style', style);
    }

    if (classes) {
      element.classList.add(...classes);
    }
  }
}
